<template>
  <div class="text-right" :style="{height:heights+'px'}">
    <div style="margin-bottom: 0.46rem;padding-top: 0.245rem;">
      <div class="urlfmIncome">
        <div class="Coins">
          <div style="display:flex;align-items: center;justify-content: center;">
            <span class="thisWeekCoins">{{walletInfo.diamond|playvolume}}</span><img src="../../assets/zs.svg" alt="">
          </div>
          <div class="extractable">
            <div>{{$t('transfer.contact_content35')}}</div>
          </div>
        </div>
      </div>
      <div class="details">
        {{$t('transfer.contact_content34')}}
      </div>
      <div :style="{height:slideh+'px'}" style='overflow: hidden'>
        <van-list v-if="diamondUserList!=''" :style="{height:slideh+'px'}" style='overflow: scroll' v-model="loading" :finished="finished" :loading-text="$t('transfer.Loading')" :error.sync="error" :error-text="$t('transfer.error')" @load="getRecellerDiamondRecord">
          <div style="border-top:0.17rem solid #F6F7FB" v-for="(item, index) in diamondUserList" :key="index" :style="index==0?'border-top:0':''">
            <div class="flexs usercss" :style="index==0?'padding-top:0':''">
              <div class="flexs" style="flex-grow:1">
                <img style="width:0.44rem;border-radius: 50%;" :src="item.userIcon" alt="">
                <div class="username">{{item.name}}</div>
              </div>
              <div class="zztype">{{$t('transfer.contact_content28')}}</div>
            </div>
            <div class="flexs" style="margin: 0 16px 0.34rem 16px;">
              <img style="width:0.63rem;height:0.63rem" src="../../assets/zs.svg" alt="">
              <div style="margin-left:0.51rem" class="shuliang">
                <div class="jine" style="margin-bottom:0.17rem">{{$t('transfer.contact_content24')}}： {{item.num}}</div>
                <div class="jine">{{ $completes.formatDate(item.creatTime)}}</div>
              </div>
            </div>
          </div>
          <div style="height:1.8rem"></div>
        </van-list>
        <div v-else class="norecord">
          <div style="text-align:center">
            <img style="width:2.56rem;height:2.56rem" src="../../assets/transfer/record.png" alt="">
            <div class="noTransfer">{{$t('transfer.contact_content31')}}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="" class="anniuz">
      <div class="flexs" style="justify-content: center;margin: 0.256rem 16px 0.8rem;">
        <div class="binding bindingleft" @click="transfer()">
          {{$t('transfer.contact_content33')}}</div>
        <div class="binding bindingright" @click="recharge()">
          {{$t('transfer.contact_content1')}}</div>
      </div>
    </div>
    <van-popup v-model="exchangeShow" round :style="{ width: '100%' }" @click-overlay="guanbi" class="exchangeShow">
      <div class="exchangeShowbox">
        <div class="Exchangetitle">{{$t('transfer.contact_content2')}}</div>
        <div style="height:6.2rem;max-height:6.2rem; overflow: scroll;">
          <div class="Exchangebox" v-for="(item, index) in exchangelist" :key="index">
            <div class="Exchangeboxsp" :style="index==0?'border-top:0':''">
              <div>
                <div class="gjname">{{$t('nation.'+item.country) }}</div>
                <div v-for="(items, index) in item.contactInformation.facebook" class="flexs" style=" margin-bottom: 0.34rem;">
                  <div style="flex-grow: 1;direction: ltr; word-break: break-all;">{{$i18n.locale=='ar'?items+' :Facebook':'Facebook: '+items}}</div>
                  <div class="copy" @click="copyThat(items)">{{$t('transfer.contact_content3')}}</div>
                </div>
                <div v-for="(items, index) in item.contactInformation.whatsapp" class="flexs" style=" margin-bottom: 0.34rem;">
                  <div style="flex-grow: 1;direction: ltr; word-break: break-all;">{{$i18n.locale=='ar'?items +' :WhatsApp':'WhatsApp: '+items}}</div>
                  <div class="copy" @click="copyThat(items)">{{$t('transfer.contact_content3')}}</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center;">
        <img class="guanbi" @click="guanbi" src="../../assets/familyIncome/guanbi.png" alt="">
      </div>
    </van-popup>
  </div>
</template>
<script>
import $ from "jquery";
import { Toast } from "vant";
import language from '../../providers/CountryCode.json'
import Cookies from 'js-cookie';

export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      userInfos: null,
      zhuanzhangShow: true,
      exchangeShow: false,
      loading: false,
      finished: false,
      error: false,
      token: null,
      uid: null,
      headers: null,
      // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOREV5IiwieHl6IjoiWmpsalpEUTVZakpqT0daaU5HTmlNV0ZpTnpWbFkyWmlNell3WmpnNVlqVT0iLCJleHAiOjE5MDc3NDM3NDB9.irA-gNwNYSD9s6_BQH2nwiQU8Zprq5BS2nyHhqwIRYY',
      // uid: 412,
      // headers: [
      //   'Content-Type:' + 'application/json; charset=utf-8',
      //   'deviceId:' + 'f9cd49b2c8fb4cb1ab75ecfb360f89b5',
      //   'lang:' + 'zh_CN',
      //   'version:' + '1.0.2',
      //   'os:' + '1',
      //   'model:' + 'M2011K2C',
      //   'fid:' + '0',
      // ],
      page: 0,
      size: 10,
      heights: 0,
      slideh: 0,
      diamondUserList: [],
      exchangelist: [],
      walletInfo: [],
    };
  },
  created() {
    // this.$completes.loadErudaJs()
    Cookies.remove('userId')
    document.getElementsByTagName("html")[0].style.padding = "0";
    //调取原生方法
    this.$completes.complete("getUserInfo");
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
      this.getUserInfo(data);
    }
  },
  mounted() {
    // this.$i18n.locale = 'ar'
    // document.querySelector('html').setAttribute('lang', this.$i18n.locale);
    document.getElementsByTagName("html")[0].style.backgroundColor = "#ffffff";
    var urlfmIncome = document.getElementsByClassName('urlfmIncome');
    var details = document.getElementsByClassName('details');
    var anniuz = document.getElementsByClassName('anniuz');
    var binding = document.getElementsByClassName('binding');
    var exchangeShowbox = document.getElementsByClassName('exchangeShowbox');
    this.heights = window.screen.height - anniuz[0].offsetHeight
    this.slideh = window.screen.height - urlfmIncome[0].offsetHeight - details[0].offsetHeight - anniuz[0].offsetHeight - binding[0].offsetHeight

  },

  filters: {
    //数字格式化
    playvolume: function (arg) {
      if (arg > 1000) {
        return arg.toLocaleString()
      }
      else {
        return arg
      }
    },
  },

  methods: {
    //copy code
    copyThat(data) {
      let url = data;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      Toast(this.$t('inviteCash.Copy_successfully'))
      oInput.remove()
    },

    getUserInfo(data) {
      this.userInfos = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      // this.serviceIp = JSON.parse(data).serviceIp
      let langs = this.userInfos.lang.substring(0, 2)
      let lang = this.userInfos.lang
      if (langs == 'zh') {
        if (lang == 'zh_TW' || lang == 'zh_HK') {
          this.$i18n.locale = 'tc'
        }
        else {
          this.$i18n.locale = 'zh'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }

      document.querySelector('html').setAttribute('lang', this.$i18n.locale);
      document.title = this.$t('transfer.contact_content33')
      this.walletInfodate()
      this.getDiamondRecord()
    },

    //关闭按钮
    guanbi() {
      this.exchangeShow = false
      $(".guanbiShow").fadeOut();
    },


    getRecellerDiamondRecord() {
      if (this.userInfos != null) {
        this.getDiamondRecord()
      }
    },

    getDiamondRecord() {
      let that = this
      let paramObjs = {
        option: 'get',
        host: this.$serviceIp + 'api/trade/receller/getRecellerDiamondRecord?token=' + that.token + '&uid=' + that.uid + '&page=' + that.page + '&size=' + that.size,
        data: '',
        header: JSON.stringify(that.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          setTimeout(() => {
            // 加载状态结束
            this.loading = false;
            this.diamondUserList = this.diamondUserList.concat(data.data.list)
            this.page = this.page + 1
            //数据全部加载完成
            if (data.data.list == '') {
              this.finished = true;

            }
          }, 1000);
        }
        else {
          this.loading = false;
          this.error = true;
          Toast(data.message || this.$t('transfer.error'))
        }
      })
    },


    //钱包信息
    walletInfodate() {
      let that = this
      let paramObjs = {
        option: 'get',
        host: this.$serviceIp + 'api/trade/receller/getRecellerWallet?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(that.headers),
      };

      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          this.walletInfo = data.data
        }
        else {
          Toast(data.message || this.$t('transfer.error'))
        }
      })
    },

    //跳转提现信息
    setBinding() {
      this.$completes.complete("setBinding");
      this.bindingShow = false
    },

    //转账
    transfer() {
      this.$router.push({ path: '/transferMoney' })
    },

    //充值联系人
    recharge() {
      this.exchangeShow = true

      let paramObjs = {
        option: 'GET',
        host: this.$serviceIp + 'api/trade/receller/getPayContacts?token=' + this.token + '&uid=' + this.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          this.exchangelist = JSON.parse(data.data)
          console.log(this.exchangelist)
        }
        else {
          Toast(data.message || this.$t('transfer.error'))
        }
      })
    },

  },

};

</script>

<style>
/* html[lang="ar"] .text-right {
  text-align: right;
}

html[lang="zh"] .text-left {
  text-align: left;
} */
html[lang="ar"] .text-right {
  direction: rtl;
}

html[lang="ar"] .urlfmIncome {
  direction: rtl;
}

html[lang="ar"] .Coins img {
  margin-left: 0.12rem;
  margin-right: 0.12rem;
}

html[lang="ar"] .details {
  border-left: 0;
  border-right: 3px solid #5dc48a;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

html[lang="ar"] .username {
  margin-right: 0.17rem;
  margin-left: 0;
}

html[lang="ar"] .shuliang {
  margin-right: 0.51rem;
  margin-left: 0 !important;
}

.flexs {
  display: flex;
  align-items: center;
}

.usercss {
  padding: 0.34rem 16px 0.136rem 16px;
  border-bottom: 1px solid #f6f7fb;
  margin-bottom: 0.34rem;
}

.urlfmIncome {
  /* width: 100%; */
  height: 1.62rem;
  background-image: url("../../assets/transfer/bg.png");
  background-size: 100% 100%;
  padding: 0.27rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px 0 16px;
}

.earnings {
  display: flex;
  align-items: center;
  font-family: mediums;
  font-size: 0.25rem;
}

.earnings img {
  width: 0.3rem;
  height: 0.3rem;
  margin-right: 0.1rem;
  position: relative;
}

.frozen {
  font-size: 0.188rem;
  position: absolute;
  color: #000000;
  font-family: Regular;
  margin-top: 0.1rem;
  line-height: 1;
  display: flex;
  align-items: center;
}

.Coins {
  color: #000000;
  text-align: center;
}

.Coins img {
  width: 0.34rem;
  height: 0.34rem;
  margin-left: 0.12rem;
}

.thisWeekCoins {
  font-size: 0.478rem;
  font-family: Semibold;
}

.extractable {
  color: #707480;
  font-family: Regular;
  font-size: 0.188rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details {
  color: #000000;
  font-size: 0.239rem;
  font-family: mediums;
  border-left: 3px solid #5dc48a;
  line-height: 0.9;
  padding-left: 0.1rem;
  margin: 0.51rem 16px 0.25rem 16px;
}

.username {
  font-size: 0.2rem;
  font-family: mediums;
  color: #000000;
  margin-left: 0.17rem;
}

.zztype {
  color: #666666;
  font-size: 0.188rem;
  font-family: Regular;
}

.jine {
  color: #000000;
  font-size: 0.188rem;
  font-family: Regular;
  line-height: 1;
}

.binding {
  background: #5dc48a;
  color: #ffffff;
  font-size: 0.27rem;
  border-radius: 50px;
  padding: 0.28rem 0;
  text-align: center;
  line-height: 1;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: auto;
  left: 0;
  right: 0;
  margin: 0 0.15rem 0;
  padding: 0.33rem 0;
  width: 50%;
}

.bindingright {
  margin-right: 0;
}

.anniuz {
  background: #ffffff;
  position: fixed;
  bottom: -1px;
  width: 100%;
}

.guanbi {
  /* position: absolute; */
  bottom: 10%;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 0.478rem;
  height: 0.478rem;
}

.Exchangetitle {
  font-family: mediums;
  color: #000000;
  font-size: 0.27rem;
  text-align: center;
  padding: 0.264rem 0 0.27rem;
  background: #ffffff;
}

.exchangeShowbox {
  padding: 0 0 25px;
  width: 90%;
  background: #f6f7fb;
  border-radius: 25px;
  position: relative;
  margin: auto;
  height: 7rem;
  max-height: 7rem;
  overflow: hidden;
}

.Exchangebox {
  font-size: 0.2rem;
  background: #f6f7fb;
  padding-bottom: 0.17rem;
}

.Exchangeboxsp {
  margin: 0 0.2rem;
  padding: 0.49rem 0.498rem 0;
  border-top: 0.7px dashed #a1a1a1;
}

.gjname {
  color: #000000;
  font-size: 0.256rem;
  font-family: mediums;
  padding-bottom: 0.16rem;
  line-height: 1;
}

.exchangeShow {
  /* display: flex;
    justify-content: center; */
  background: none;
}

.copy {
  width: 30px;
  background: #5dc48a;
  color: #ffffff;
  padding: 0.11rem 0.27rem;
  border-radius: 24px;
  line-height: 1;
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>